/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import "../node_modules/@fortawesome/fontawesome-free/css/fontawesome.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/solid.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/brands.css";

@import "assets/css/ng-select";

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";

.fas {
    font-family: 'Font Awesome 6 Free' !important;
}

.red-text {
    color: red;
}

.p-dialog-content {
    overflow-y: visible !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

.p-dialog {
    overflow-y: scroll !important;
    background-color: white !important;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #c5d2dd;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #96a3b2;
}

.field {
    margin-bottom: 10px;
}

p-fieldset {
    padding-top: 20px;
}

.fields-group {
    margin-top: 20px;
}

.p-menuitem-icon {
    font-size: large;
}

.p-menuitem-link-active {
    background-color: #16925c !important;
}

.p-menuitem-link-active > .p-menuitem-text {
    color: white !important;
}

.p-menuitem-link-active > .p-menuitem-icon {
    color: white !important;
}

.p-menuitem-link:hover {
    background-color: #eff5fa !important;
}

.p-menuitem-link-active:hover {
    background-color: #16925c !important;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.isDeleted > .p-accordion-header-link {
    background-color: red;
    color: white;
}

.addEditProgressBar {
    margin-bottom: 15px !important;
}

.actions-area {
    position: static;
    margin-top: 20px;
    bottom: 20px;
    right: 20px;
    left: 20px;
    display: flex;
    justify-content: space-between;
}

.p-float-label > label {
    // margin-left: -27px;
}

.layout-menu-container {
    background: linear-gradient(180deg, #17925C 60%, rgba(23, 146, 92, 0) 100%);
}


.menu-wrapper {
    background: darkgreen url(assets/layout/images/vitall_menu_image.png) no-repeat bottom;
}

.layout-menu-container > h2 {
    text-align: center;
    font-family: 'Arial';
    font-style: italic;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 151% */

    text-align: center;

    color: #FFFFFF;
}

.layout-menu-dim .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    background-color: #158152 !important;
    color: #ffffff;
}

.layout-menu-dim .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: #158152 !important;
    color: #ffffff;
}


